import React, { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Form, Grid, Icon, Modal } from "semantic-ui-react";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import CardNurseNoteUX from "./CardNurseNoteUX";
import ModProgressNoteForm from "./ModProgressNoteFormUX";
import ModAuthen from "react-lib/apps/common/ModAuthen";
import * as utils from "react-lib/utils";
import moment from "moment";

import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import CardSelectDoctorOrder from "react-lib/apps/PTM/CardSelectDoctorOrder";
import { useIntl } from "react-intl";

const CARD_NURSE_NOTE = "cardNurseNote";
const CardNurseNote = (props: any) => {
  const intl = useIntl();
  const [openModAuthen, setOpenModAuthen] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [openModSelectDoctorOrder, setOpenModSelectDoctorOrder] = useState<boolean>(false);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);

  useEffect(() => {
    return () => {
      props.onEvent({
        message: "HandleNurseNote",
        params: {
          action: "clear",
        },
      });
    };
  }, []);

  useEffect(() => {
    props.onEvent({
      message: "HandleNurseNote",
      params: {
        action: "search",
        encounterId: props.selectedEncounter?.id,
        card: CARD_NURSE_NOTE,
      },
    });
  }, [props.selectedEncounter]);

  useEffect(() => {
    setOpenModAuthen(false);
  }, [props.openModNurseNoteForm]);

  const handleConfirmSaveNurseNote = ({ username, password }) => {
    props.onEvent({
      message: "HandleNurseNote",
      params: {
        action: "save",
        date: utils.formatDatetime(moment()).replace(" ", "-"),
        encounterId: props.selectedEncounter?.id,
        username,
        password,
        card: CARD_NURSE_NOTE,
      },
    });
  };

  const handleConfirmRemoveNurseNote = ({ username, password }) => {
    props.onEvent({
      message: "HandleNurseNote",
      params: {
        action: "remove",
        encounterId: props.selectedEncounter?.id,
        username,
        password,
        card: CARD_NURSE_NOTE,
      },
    });
  };

  const handleChecked = (checked: boolean | undefined, item: any) => {
    const ids: number[] = item.id_list || [item.id];

    if (checkedIds.some((id) => ids.includes(id))) {
      setCheckedIds(checkedIds.filter((id) => !ids.includes(id)));
    } else {
      setCheckedIds([...checkedIds, ...ids]);
    }
  };

  const nurseNoteList = useMemo(() => {
    return (props.nurseNoteFilter?.nurseNoteList || []).map((item: any, index: number) => ({
      ...item,
      formatted_date: (
        <Form>
          <Form.Group inline>
            <Form.Field width={3}>
              <div style={{ marginRight: "20px" }}>
                <Checkbox
                  id={`CardNurseNote-Item-Checkbox-${index}`}
                  checked={checkedIds.includes(item.id)}
                  onChange={(e, v) => {
                    handleChecked(v.checked, item);
                  }}
                />
              </div>
            </Form.Field>
            <Form.Field width={13}>
              <div>{(item.formatted_date || "").replaceAll(" ", "\n")}</div>
            </Form.Field>
          </Form.Group>
          {["IPD", "SS"].includes(props.selectedEncounter.type) && (
            <Form.Group inline>
              <Form.Field width={3}></Form.Field>
              <Form.Field width={13}>
                <div>{item.working_shift || ""}</div>
              </Form.Field>
            </Form.Group>
          )}
        </Form>
      ),
      focus: (
        <Form>
          <Form.Group inline>
            <Form.Field>
              <div>{item.focus || ""}</div>
            </Form.Field>
          </Form.Group>
          {/* <Form.Group inline>
            <Form.Field>
              <div>{item.doctor_order_id || ""}</div>
            </Form.Field>
          </Form.Group> */}
        </Form>
      ),
      progress_note: (
        <div style={{ wordWrap: "break-word", padding: "2px" }}>
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column width={2} style={{ fontWeight: "bold", textAlign: "center" }}>
                A:
              </Grid.Column>
              <Grid.Column width={14}>{item.diagnosis}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={2} style={{ fontWeight: "bold", textAlign: "center" }}>
                I:
              </Grid.Column>
              <Grid.Column width={14}>{item.plan}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={2} style={{ fontWeight: "bold", textAlign: "center" }}>
                E:
              </Grid.Column>
              <Grid.Column width={14}>{item.goal}</Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      ),
      _action: (
        <>
          <Button
            id={`CardNurseNote-Item-Button-Edit-${index}`}
            color="yellow"
            icon="pencil"
            onClick={() => {
              setAction("edit");
              props.onEvent({
                message: "HandleNurseNote",
                params: {
                  action: "edit",
                  card: CARD_NURSE_NOTE,
                  selectedNurseNote: item,
                },
              });
            }}
          />
          <Button
            id={`CardNurseNote-Item-Button-Delete-${index}`}
            color="red"
            icon="trash"
            onClick={() => {
              props.setProp("selectedNurseNote", item);
              // setAction("remove");
              setOpenModAuthen(true);
            }}
          />
        </>
      ),
    }));
  }, [props.nurseNoteFilter?.nurseNoteList, checkedIds]);

  const handleOpenSelectDoctorOrder = () => {
    setOpenModSelectDoctorOrder(true);
  };

  console.log("Card Nurse Note: ", props);

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={async () => {
          props.setProp(`errorMessage.${CARD_NURSE_NOTE}`, null);
          props.setProp(`successMessage.${CARD_NURSE_NOTE}`, null);
        }}
        error={props.errorMessage?.[CARD_NURSE_NOTE]}
        success={props.successMessage?.[CARD_NURSE_NOTE]}
        languageUX={props.languageUX}
      />
      <CardNurseNoteUX
        // data
        selectedStartDate={props.nurseNoteFilter?.startDate}
        selectedEndDate={props.nurseNoteFilter.endDate}
        nurseNoteList={nurseNoteList}
        showPrint={checkedIds.length === 0}
        loadingPrint={!!props.buttonLoadCheck?.[`${CARD_NURSE_NOTE}_PRINT`]}
        selectedNurseNote={props.selectedNurseNote}
        // function
        openModalNote={() => {
          setAction("add");
          props.onEvent({
            message: "HandleNurseNote",
            params: { action: "new", card: CARD_NURSE_NOTE },
          });
        }}
        nurseNoteLoading={props.nurseNoteLoading}
        nurseNoteSearch={() => {
          props.onEvent({
            message: "HandleNurseNote",
            params: {
              action: "search",
              card: CARD_NURSE_NOTE,
              encounterId: props.selectedEncounter?.id,
            },
          });
        }}
        onSetStartDate={(date: string) => {
          props.setProp("nurseNoteFilter.startDate", date);
        }}
        onSetEndDate={(date: string) => {
          props.setProp("nurseNoteFilter.endDate", date);
        }}
        handleNurseNoteReport={() => {
          props.onEvent({
            message: "handleNurseNoteReport",
            params: {
              card: CARD_NURSE_NOTE,
              encounter: props.selectedEncounter?.id,
              checkedIds: checkedIds,
            },
          });
        }}
        onSelectedRow={(state, row, column, instance) => {
          return {
            onClick: () => {
              if (row.original) {
                let nurseNoteData = (props.nurseNoteFilter?.nurseNoteList || []).find(
                  (items: any) => items.id === row.original?.id
                );
                props.setProp("selectedNurseNote", nurseNoteData);
              }
            },
            className:
              row?.original && row?.original?.id === props.selectedNurseNote?.id
                ? "blueSelectedRow"
                : "",
            style: {
              cursor: "pointer",
            },
          };
        }}
        isNurseForm={props.isNurseForm}
        languageUX={props.languageUX}
      />
      <ModAuthen
        titleName={intl.formatMessage({ id: "ระบุ Username และ Password เพื่อลบ" })}
        titleColor={"red"}
        open={openModAuthen}
        onDeny={() => setOpenModAuthen(false)}
        // error={props.authenError}
        onApprove={({ username, password }) => {
          handleConfirmRemoveNurseNote({ username, password });
          setOpenModAuthen(false);
        }}
      />

      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
        languageUX={props.languageUX}
      />

      <Modal open={props.openModNurseNoteForm}>
        <ModProgressNoteForm
          closeIcon={
            <Icon
              name="cancel"
              color="red"
              size="large"
              style={{ margin: "-30px -30px 0px 0px", cursor: "pointer" }}
              onClick={() => {
                props.setProp("openModNurseNoteForm", false);
              }}
            />
          }
          dateCreate={utils.formatDatetime(moment())}
          username={username}
          password={password}
          onChangeUsername={(e: any) => {
            setUsername(e.target.value);
          }}
          onChangePassword={(e: any) => {
            setPassword(e.target.value);
          }}
          selectedNurseNote={props.selectedNurseNote}
          setProp={props.setProp}
          django={props.django}
          handleSaveNurseNote={() => {
            // setOpenModAuthen(true);
            if (action === "add" || action === "edit") {
              handleConfirmSaveNurseNote({ username, password });
            } else if (action === "remove") {
              handleConfirmRemoveNurseNote({ username, password });
            }
            setUsername("");
            setPassword("");
          }}
          nurseNoteFocusOptions={props.nurseNoteFocusOptions}
          onChangeDropdown={(e: any, data: any) => {
            props.onEvent({
              message: "HandleNurseNote",
              params: {
                action: "change_option",
                data: data.value,
                card: CARD_NURSE_NOTE,
              },
            });
          }}
          handleAddNurseNoteFocusOptions={(e: any, data: any) => {
            props.onEvent({
              message: "HandleNurseNote",
              params: {
                action: "add_option",
                data: data.value,
                card: CARD_NURSE_NOTE,
              },
            });
          }}
          isOPDEncounter={props.selectedEncounter?.type === "OPD"}
          onOpenSelectDoctorOrder={handleOpenSelectDoctorOrder}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal
        open={openModSelectDoctorOrder}
        onClose={() => setOpenModSelectDoctorOrder(false)}
        size="large"
      >
        <CardSelectDoctorOrder
          controller={props.nurseNoteController}
          patientData={props.patientData}
          toggleable={false}
          encounter={props.selectedEncounter?.id}
          onClose={() => setOpenModSelectDoctorOrder(false)}
          // onSelect={handleSelectDoctorOrder}
          languageUX={props.languageUX}
        />
      </Modal>
    </>
  );
};

export default CardNurseNote;
