import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'

const CardOPDOrderSummaryUX = (props: any) => {
    return(
      <div
        id="CardOPDOrderSummaryUX-div-0"
        style={{height: "100%", width: "100%", padding: "10px", maxHeight: "calc(100dvh - 60px)", overflowY: "auto"}}>
        <div
          id="CardOPDOrderSummaryUX-div-1"
          style={{fontSize: "20px", fontWeight: "bold",padding: "10px"}}>
          รายการคำสั่งแพทย์
        </div>
        <div
          id="CardOPDOrderSummaryUX-div-2"
          style={{backgroundColor: "#f5f5f5", padding: "10px"}}>
          
          <Button
            color="blue"
            id="CardOPDOrderSummaryUX-Button-7"
            onClick={props.onCheckedAll}>
            สรุปรายการทั้งหมด
          </Button>
          <div
            id="CardOPDOrderSummaryUX-div-5"
            style={{paddingTop: "10px", width: "100%"}}>
            
            <Form
              id="CardOPDOrderSummaryUX-Form-36">
              <FormGroup
                id="CardOPDOrderSummaryUX-FormGroup-37">
                <FormField
                  id="CardOPDOrderSummaryUX-FormField-38"
                  inline={true}
                  width="2">
                  <Checkbox
                    checked={props.isOrder}
                    id="CardOPDOrderSummaryUX-Checkbox-39"
                    label="สถานะคำสั่ง"
                    onChange={props.onChangeChecked("isOrder")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                </FormField>
                <FormField
                  className="four width"
                  id="CardOPDOrderSummaryUX-FormField-40"
                  width="4">
                  <Dropdown
                    id="CardOPDOrderSummaryUX-Dropdown-41"
                    onChange={props.onOrderTypeChange}
                    options={props.orderTypeOptions}
                    selection={true}
                    value={props.orderType}>
                  </Dropdown>
                </FormField>
                <FormField
                  id="CardOPDOrderSummaryUX-FormField-42"
                  width="1">
                </FormField>
                <FormField
                  id="CardOPDOrderSummaryUX-FormField-43"
                  width="8">
                  <Checkbox
                    checked={props.isLab}
                    id="CardOPDOrderSummaryUX-Checkbox-44"
                    label="Lab"
                    onChange={props.onChangeChecked("isLab")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                  <Checkbox
                    checked={props.isImaging}
                    id="CardOPDOrderSummaryUX-Checkbox-45"
                    label="Imaging"
                    onChange={props.onChangeChecked("isImaging")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                  <Checkbox
                    checked={props.isTreatment}
                    id="CardOPDOrderSummaryUX-Checkbox-46"
                    label="Treatment"
                    onChange={props.onChangeChecked("isTreatment")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                  <Checkbox
                    checked={props.isOthers}
                    id="CardOPDOrderSummaryUX-Checkbox-47"
                    label="อื่นๆ"
                    onChange={props.onChangeChecked("isOthers")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                  <Checkbox
                    checked={props.isOutPerformDiv}
                    id="CardOPDOrderSummaryUX-Checkbox-48"
                    label="คำสั่งนอกแผนก"
                    onChange={props.onChangeChecked("isOutPerformDiv")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                </FormField>
                <FormField
                  id="CardOPDOrderSummaryUX-FormField-49"
                  width="3">
                  <Button
                    color="blue"
                    id="CardOPDOrderSummaryUX-Button-50"
                    loading={props.loadingSummary}
                    onClick={props.onSearch}>
                    ค้นหา
                  </Button>
                </FormField>
              </FormGroup>
            </Form>
          </div>
        </div>
        <div
          id="CardOPDOrderSummaryUX-div-3"
          style={{paddingTop: "10px"}}>
          
          <Table
            className="-customTablePadding"
            columns={props.orderSummaryColumns}
            data={props.orderSummaryOPD}
            getTrProps={props.onSelectedOrder}
            headers="Order Time,รายการคำสั่งแพทย์ (1 day),เวลารับ,พยาบาลผู้รับคำสั่ง,สถานะ"
            id="CardOPDOrderSummaryUX-Table-6"
            keys="order_time,detail,order_ack_time,order_ack_by_name,status"
            minRows="12"
            showPagination={false}
            style={{height: "600px"}}
            widths="200,,200,200,200">
          </Table>
        </div>
        <div
          id="CardOPDOrderSummaryUX-div-52"
          style={{ display: "flex", justifyContent: " flex-end", marginTop : "10px" }}>
          
          <Button
            color="green"
            disabled={props.disabledNursePerform}
            id="CardOPDOrderSummaryUX-Button-53"
            onClick={props.buttonNursePerform}
            size="small"
            style={{ width: "150px" }}>
            รับคำสั่ง
          </Button>
        </div>
      </div>
    )
}


export default CardOPDOrderSummaryUX

export const screenPropsDefault = {}

/* Date Time : Thu Jan 30 2025 14:28:28 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width: \"100%\", padding: \"10px\", maxHeight: \"calc(100dvh - 60px)\", overflowY: \"auto\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการคำสั่งแพทย์"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"20px\", fontWeight: \"bold\",padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-div-2"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#f5f5f5\", padding: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-div-3"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-div-5"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"10px\", width: \"100%\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 6,
      "name": "Table",
      "parent": 3,
      "props": {
        "className": {
          "type": "value",
          "value": "-customTablePadding"
        },
        "columns": {
          "type": "code",
          "value": "props.orderSummaryColumns"
        },
        "data": {
          "type": "code",
          "value": "props.orderSummaryOPD"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectedOrder"
        },
        "headers": {
          "type": "value",
          "value": "Order Time,รายการคำสั่งแพทย์ (1 day),เวลารับ,พยาบาลผู้รับคำสั่ง,สถานะ"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Table-6"
        },
        "keys": {
          "type": "value",
          "value": "order_time,detail,order_ack_time,order_ack_by_name,status"
        },
        "minRows": {
          "type": "value",
          "value": "12"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"600px\"}"
        },
        "widths": {
          "type": "value",
          "value": "200,,200,200,200"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "สรุปรายการทั้งหมด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Button-7"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCheckedAll"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Form",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Form-36"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormGroup",
      "parent": 36,
      "props": {
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-FormGroup-37"
        },
        "inline": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-FormField-38"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Checkbox",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOrder"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Checkbox-39"
        },
        "label": {
          "type": "value",
          "value": "สถานะคำสั่ง"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isOrder\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 37,
      "props": {
        "className": {
          "type": "value",
          "value": "four width"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-FormField-40"
        },
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Dropdown",
      "parent": 40,
      "props": {
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Dropdown-41"
        },
        "onChange": {
          "type": "code",
          "value": "props.onOrderTypeChange"
        },
        "options": {
          "type": "code",
          "value": "props.orderTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.orderType"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-FormField-42"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-FormField-43"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isLab"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Checkbox-44"
        },
        "label": {
          "type": "value",
          "value": "Lab"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isLab\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isImaging"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Checkbox-45"
        },
        "label": {
          "type": "value",
          "value": "Imaging"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isImaging\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isTreatment"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Checkbox-46"
        },
        "label": {
          "type": "value",
          "value": "Treatment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isTreatment\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOthers"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Checkbox-47"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isOthers\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOutPerformDiv"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Checkbox-48"
        },
        "label": {
          "type": "value",
          "value": "คำสั่งนอกแผนก"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isOutPerformDiv\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-FormField-49"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Button",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Button-50"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingSummary"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-div-52"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \" flex-end\", marginTop : \"10px\" }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Button",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "รับคำสั่ง"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledNursePerform"
        },
        "id": {
          "type": "value",
          "value": "CardOPDOrderSummaryUX-Button-53"
        },
        "onClick": {
          "type": "code",
          "value": "props.buttonNursePerform"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"150px\" }"
        }
      },
      "seq": 53,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardOPDOrderSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
